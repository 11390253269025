@tailwind base;
@tailwind components;
@tailwind utilities;

/* General */
*::-webkit-scrollbar {
  @apply h-[5px] w-[5px];
}

*::-webkit-scrollbar-thumb {
  @apply rounded-lg bg-primary-yellow;
}

*::-webkit-scrollbar-track {
  @apply bg-[#E0E0E0];
}

html,
body,
#root {
  @apply lg:h-full;
}

body {
  @apply lg:overflow-auto text-accent-black;
}

/* Auth */
.auth-wrap {
  @apply relative overflow-auto lg:h-screen;
}

.form-card {
  @apply mt-10 xl:mx-10 xl:mt-14;
}

/* Icon */
.icon-arrow {
  @apply flex h-[40px] w-[40px] items-center justify-center rounded-full border-2 p-2 md:h-[50px] md:w-[50px];
}

/* Sidebar */
.sidebar {
  @apply  h-full fixed z-10 flex-row flex-nowrap items-center justify-between  overflow-hidden  overflow-y-auto rounded-[30px] bg-white pt-4 transition-all duration-300 lg:relative lg:m-5 lg:block lg:h-[95vh] lg:w-[8vw] ml-[-99rem] lg:ml-0;
}

.sidebar .text {
  @apply transition-all lg:indent-[-99rem];
}

.sidebar .sidebar-heading img {
  @apply hidden
}

.sidebar.active {
  @apply fixed z-50 block w-[80vw] lg:relative lg:ml-5 lg:w-[350px] ml-0;
}

.sidebar.active .text {
  text-indent: inherit;
}

.sidebar .sidebar-heading img.active {
  @apply block
}

.list-sidebar {
  @apply text-medium-gray;
}

.list-sidebar li {
  @apply items-center rounded-r-lg border-l-4 border-l-transparent px-6 transition hover:text-accent-black;
}

.list-sidebar svg {
  @apply w-[25px];
}

.list-sidebar svg path {
  @apply fill-medium-gray transition;
}

.list-sidebar li:hover svg path,
.list-sidebar li.active svg path {
  @apply fill-accent-black;
}

.list-sidebar li.active {
  @apply border-l-primary-yellow bg-[#F4AE0054] text-accent-black;
}

/* Button */
.button {
  @apply rounded-[10px] px-4 py-2 transition duration-300 hover:opacity-80 disabled:cursor-not-allowed disabled:bg-cream disabled:text-accent-black disabled:opacity-75;
}

.kQcYu, .sc-gswNZR {
  @apply !relative;
}

/* Swal */
body.swal2-shown.swal2-height-auto {
  @apply !h-full;
}

/* Text */
.text-en {
  @apply block text-[0.75em];
}